
import { useEffect, useRef, useState } from 'react';
import { useAppData } from '../contexts/AppContext';
import { signOut } from '../repositories/firebase';

const TIMEOUT_DURATION = 60 * 60 * 1000; // 60 minutes in milliseconds

export const useAutoLogout = () => {
    const { setAuthenticated } = useAppData();
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);
    const intervalRef = useRef<NodeJS.Timeout | null>(null);
    const [timeLeft, setTimeLeft] = useState(TIMEOUT_DURATION);

    const resetTimeout = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }
        setTimeLeft(TIMEOUT_DURATION);
        console.log('Timer reset. Time left:', TIMEOUT_DURATION / 1000, 'seconds');

        timeoutRef.current = setTimeout(logout, TIMEOUT_DURATION);

        intervalRef.current = setInterval(() => {
            setTimeLeft((prevTime) => {
                const newTime = prevTime - 1000;
                console.log('Time left:', newTime / 1000, 'seconds');
                return newTime;
            });
        }, 1000);
    };

    const logout = async () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }
        console.log('Logging out due to inactivity');
        await signOut();
        setAuthenticated(false);
    };

    useEffect(() => {
        const events = ['mousedown', 'keypress', 'scroll', 'touchstart'];

        const handleActivity = () => {
            console.log('Activity detected, resetting timer');
            resetTimeout();
        };

        events.forEach(event => {
            document.addEventListener(event, handleActivity);
        });

        resetTimeout(); // Start the initial timer

        return () => {
            events.forEach(event => {
                document.removeEventListener(event, handleActivity);
            });
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, []);

    return { resetTimeout, timeLeft };
};
